import React, { useState, useEffect } from 'react';
import fetch from 'isomorphic-fetch';
import { createClient } from 'contentful-management';
import {
  Button,
  Dropdown,
  DropdownList,
  DropdownListItem,
  FormLabel,
  HelpText,
  Note,
  Paragraph,
  Textarea,
  Tooltip,
} from '@contentful/forma-36-react-components';
import { TRANSLATION_TAG_PREFIX } from '../../utils/constants';

import './StartTranslation.css';

const ENTRY_STATE = {
  delivery: 'Published',
  preview: 'Draft',
};

type translationNamespaceMapItem = {
  title: string,
  namespace: string,
  description: string,
};

let contentfulEnvironmentClient: any;

const StartTranslation = ({ sdk }: { sdk: any }) => {
  const [isEntryStateOpen, setEntryStateOpen] = useState(false);
  const [isNamespaceStateOpen, setNamespaceStateOpen] = useState(false);
  const [selectedEntryState, setSelectedEntryState] = useState('');
  const [selectedNamespace, setSelectedNamespace] = useState('');
  const [instructions, setInstructions] = useState('');
  const [isInProgress, setIsInProgress] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');

  const {
    parameters: {
      installation: {
        deliveryAccessToken = '',
        previewAccessToken = '',
        managementAccessToken = '',
        translationServiceUrl = '',
        enableTestingMode = false,
        translationNamespaceMap = [],
      } = {},
    } = {},
    ids: {
      space = '',
      entry = '',
      environment = '',
    } = {},
    user: {
      firstName = '',
      lastName = '',
    } = {},
  } = sdk;

  useEffect(() => {
    const client: any = createClient({
      accessToken: managementAccessToken,
    });

    client.getSpace(space).then((spaceClient: any) => {
      return spaceClient.getEnvironment(environment).then((environmentClient: any) => {
        contentfulEnvironmentClient = environmentClient;
      })
    });
  }, [space, managementAccessToken, environment])

  const handleStartTranslation = () => {
    setIsInProgress(true);

    const apiType = selectedEntryState === 'Published' ? 'delivery' : 'preview';

    // get translation keys
    fetch(`${translationServiceUrl}/page/${entry}/keys?apiType=${apiType}&environment=${environment}`, {
      headers: {
        'X-Contentful-Delivery-Access-Token': deliveryAccessToken,
        'X-Contentful-Preview-Access-Token': previewAccessToken,
        'X-Contentful-Space': space,
      }
    }).then(
      res => res.json()
    ).then(
      (res) => {
        if (res.status >= 400) {
          return Promise.reject(res);
        }

        console.log(`Submitting the translation request to: ${translationServiceUrl}/page/${entry}/keys/${selectedNamespace}`);

        const submitter = firstName && lastName ? {
          submitter: `${firstName} ${lastName}`,
        } : {};

        // submit translation request
        return !enableTestingMode ? fetch(`${translationServiceUrl}/page/${entry}/keys/${selectedNamespace}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...res,
            instructions,
            ...submitter,
          }),
        }).then(
          res => res.json()
        ) : Promise.resolve({ status: 200 });
      }
    ).then(
      (res) => {
        if (res.status >= 400) {
          return Promise.reject(res);
        }
        // show success status
        setErrorInfo('');
        setIsInProgress(false);
        setShowSuccessMessage(true);

        // once successfully submitted a translation request
        // add a tag to the entry, which will be used for fetching translations
        const updateEntryWithTag = ({ tagId }: any) => {
          return contentfulEnvironmentClient.getEntry(entry).then((entryInfo: any) => {
            // tag the entry with the tag
            const tagEntry = {
              sys: { type: 'Link', linkType: 'Tag', id: tagId },
            };

            entryInfo?.metadata?.tags?.push(tagEntry);
            return entryInfo.update();
          }).catch((err: any) => {
            sdk.notifier.error(`Failed to automatically tag the entry with "${tagId}". Please manually add the tag the entry or try resubmitting the transation.`);
          });
        };

        // create a tag with translation namespace if it doesn't exist
        const tagId = `${TRANSLATION_TAG_PREFIX}${selectedNamespace}`;
        // return sdk.space.createTag(tagId, tagId).then(() => {
        return contentfulEnvironmentClient.createTag(tagId, tagId).then(() => {
          console.log(`The tag "${tagId}" is successfully created.`);
          return updateEntryWithTag({tagId});
        }).catch((e: any) => {
          const message = e && JSON.parse(e?.message);

          if (message?.status === 409 || message.status?.status === 422) {
            // the tag already exists
            console.log(`The tag "${tagId}" already exists.`);
            return updateEntryWithTag({tagId});
          }

          // some other error
          console.log(`Error creating a tag: ${message}`);
        })
      }
    ).catch(
      (error) => {
        console.log(error)
        // show failure status
        setErrorInfo(JSON.stringify(error));
        setIsInProgress(false);
        setShowFailureMessage(true);
      }
    );
  };

  const selectedNamespaceTitle = translationNamespaceMap.find((e: translationNamespaceMapItem) => e.namespace === selectedNamespace)?.title;

  return (
    <div className="wrapper">
      {
        !showSuccessMessage ? (
          <>
            {
              showFailureMessage && (
                <div className="failure-message-wrapper">
                  <Note title="Oops, something went wrong! Please try again." noteType="warning" className="failure-message-note">
                    <div className="failure-error-info">{ `${errorInfo}` }</div>
                  </Note>
                </div>
              )
            }
            <div className="dropdown-wrapper">
              <Dropdown
                className="entry-state-dropdown"
                isOpen={isEntryStateOpen}
                onClose={() => setEntryStateOpen(false)}
                toggleElement={
                  <Button
                    size="small"
                    buttonType="muted"
                    indicateDropdown
                    onClick={() => setEntryStateOpen(!isEntryStateOpen)}
                  >
                    { selectedEntryState || 'Choose the entry state' }
                  </Button>
                }
              >
                <DropdownList>
                  <DropdownListItem onClick={() => setSelectedEntryState(ENTRY_STATE.delivery)}>
                    Published
                  </DropdownListItem>
                  <DropdownListItem onClick={() => setSelectedEntryState(ENTRY_STATE.preview)}>
                    Draft
                  </DropdownListItem>
                </DropdownList>
              </Dropdown>
              <Dropdown
                className="namespace-dropdown"
                isOpen={isNamespaceStateOpen}
                onClose={() => setNamespaceStateOpen(false)}
                toggleElement={
                  <Button
                    size="small"
                    buttonType="muted"
                    indicateDropdown
                    onClick={() => setNamespaceStateOpen(!isNamespaceStateOpen)}
                  >
                    { selectedNamespaceTitle || 'Choose page or content type' }
                  </Button>
                }
              >
                <DropdownList>
                  {
                    translationNamespaceMap.map(({title, namespace, description}: translationNamespaceMapItem) => {
                      const dropdownOption = (
                        <DropdownListItem onClick={() => setSelectedNamespace(namespace)}>
                          { title }
                        </DropdownListItem>
                      );

                      return description ? (
                        <Tooltip
                          key={namespace}
                          place="right"
                          id="tip2"
                          targetWrapperClassName="targetWrapperClassName"
                          content={description}
                        >
                          { dropdownOption }
                        </Tooltip>
                      ) : (
                        <React.Fragment
                          key={namespace}
                        >
                          { dropdownOption }
                        </React.Fragment>
                      );
                    })
                  }
                </DropdownList>
              </Dropdown>
              <Button
                buttonType="primary"
                disabled={!selectedEntryState || !selectedNamespace || !instructions}
                onClick={() => handleStartTranslation()}
              >
                { isInProgress ? "Submitting..." : "Start translation" }
              </Button>
            </div>
            <div className="textarea-wrapper">
              <FormLabel htmlFor="instructions">Description:</FormLabel>
              <HelpText>Page URL <span className="bold">required</span><span className="red">*</span> (live page preferred or preview URL if the page is unpublished). If submitting a module, enter the URL of the page containing that module. Please provide context for very creative copy (i.e. collection names, copy with puns). Clarify words with multiple meanings.</HelpText>
              <Textarea
                className="textarea"
                name="instructions"
                rows={4}
                required
                value={instructions}
                placeholder="page URL __, words with multiple meanings __"
                onChange={(e) => setInstructions(e.target.value)}
              />
            </div>
          </>
        ) : (
          <div className="success-message-wrapper">
            <Note noteType="positive" className="success-message-note">
              Your page has been submitted to the translations service.
            </Note>
            <Paragraph className="success-message-paragraph">
              Please check back in approximately 5 business days to confirm translations are complete and to author them back into Contentful.
            </Paragraph>
          </div>
        )
      }
    </div>
  );
}

export default StartTranslation;
