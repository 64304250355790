import fetch from 'isomorphic-fetch';
import { TRANSLATION_TAG_PREFIX, BATCH_SIZE, THROTTLE_AMOUNT, THROTTLE_TIME } from '../utils/constants';
import throttledQueue from 'throttled-queue';

export enum Status {
  Success,
  Failure,
};

export enum SuccessTypes {
  AllTranslated = 'Congrats! It looks like all your text has been translated.',
  PartiallyTranslated = 'It looks like your translation is still in progress',
  SuccessfullyAuthored = 'Successfully authored to Contentful!',
  PartiallyAuthored = 'Some of your translations were not successfully authored. Please review your translations.',
};

export enum FailureTypes {
  NoAssociatedGroup = 'Oops, something went wrong! Have you submitted the translation request for the page? There is no associated translation group with the page.',
  OtherFailure = 'Oops, something went wrong! Please try again.',
  TimedOut = 'Sorry, your request timed out! It may have been too large or contained a problem key.'
};

/**
 * Fetches translations of an entry
 * @param translationServiceUrl 
 * @param entry 
 * @param namespace 
 * @returns 
 */
export const fetchTranslations = async (translationServiceUrl: string, entry: string, namespace: string) => {
  const res = await fetch(`${translationServiceUrl}/page/${entry}/translations/${namespace}`);
  const resJson = await res.json();

  if (resJson.status >= 400) {
    return Promise.reject(resJson);
  }
  return resJson;
};

/**
 * Fetches translations from contentful-all namespace first and then from contentful namespace
 * @param translationServiceUrl
 * @param entry 
 * @returns 
 */
export const getTranslations = async (
  translationServiceUrl: string,
  entry: string,
  sdkSpace: any,
  translationNamespaceMap: any,
) => {
  // use sdk to get the most recent tags
  const {
    metadata: {
      tags = [],
    } = {}
  } = await sdkSpace.getEntry(entry);

  const namespaces = tags?.map((tag: any) => tag?.sys?.id)
    .filter((tag: any) => tag.startsWith(TRANSLATION_TAG_PREFIX))
    .map((tag: any) => tag.replace(TRANSLATION_TAG_PREFIX, ''));

  const orderedNamespaces = translationNamespaceMap.reduce((acc: any, cur: any) => {
    if (namespaces.includes(cur.namespace)) {
      return [...acc, cur.namespace]
    }
    return acc;
  }, []);

  if (!orderedNamespaces.length) {
    // if there is no namespace tag associated with the entry
    // assume that no trnaslation request has been made
    // add a tag manually if this has to be bypassed
    return Promise.reject({ status: 404 });
  }

  // fetch translation data from each name space
  for (let index = 0; index < orderedNamespaces.length; index++) {
    try {
      const contentfulNameSpaceData = await fetchTranslations(translationServiceUrl, entry, orderedNamespaces[index]);

      // If we get translations for contentful-all namespace then use them as translations.
      return {
        namespace: orderedNamespaces[index],
        translations: contentfulNameSpaceData?.data?.translations,
      };
    } catch (e: any) {
      // 404 - group hasn't been created
      // 422 - group has been created but there is no associated tags
      if (e.status !== 404 && e.status !== 422) {
        return Promise.reject(e);
      }
      // if 404 or 422, keep going unless it's the last one
      if (index === orderedNamespaces.length - 1) {
        return Promise.reject(e);
      }
    }
  }
};

/**
 * Fetches types of fields of an entry
 * @param translationServiceUrl
 * @param entry 
 * @param deliveryAccessToken 
 * @param previewAccessToken 
 * @param space 
 * @returns 
 */
export const fetchLocalizedFieldType = async (
  translationServiceUrl: string,
  entry: string,
  deliveryAccessToken: string,
  previewAccessToken: string,
  space: string,
  environment: string,
) => {
  // Using preview api type since a user may have submitted translations with draft status
  // A field type will not change regardless of apiType
  const res = await fetch(`${translationServiceUrl}/page/${entry}/keys?valueType=fieldType&apiType=preview&environment=${environment}`, {
    headers: {
      'X-Contentful-Delivery-Access-Token': deliveryAccessToken,
      'X-Contentful-Preview-Access-Token': previewAccessToken,
      'X-Contentful-Space': space,
    }
  })
  const resJson = await res.json();

  if (resJson && resJson.status >= 400) {
    return Promise.reject(resJson);
  }

  return resJson;
};

export const checkTranslations = async (
  translationServiceUrl: string,
  entry: string,
  deliveryAccessToken: string,
  previewAccessToken: string,
  space: string,
  environment: string,
  sdkSpace: any,
  translationNamespaceMap: any,
) => {
  try {
    const [{ namespace, translations }, localizedFieldsData]: [any, any] = await Promise.all([
      getTranslations(translationServiceUrl, entry, sdkSpace, translationNamespaceMap),
      fetchLocalizedFieldType(
        translationServiceUrl,
        entry,
        deliveryAccessToken,
        previewAccessToken,
        space,
        environment,
      ),
    ]);

    // now filter the translations so that only the keys relevant to
    // the latest content of the page are taken into account
    const localizedFieldsKeys = localizedFieldsData?.data ?? {};

    // compute the result
    let numberOfCompletedKeys = 0;
    let totalNumberOfKeys = 0; // keys that are relevant to the latest status of the page. NOT all the keys from the first submission

    const filteredTranslations = Object.keys(translations).reduce((acc, key) => {
      if (!localizedFieldsKeys[key]) {
        return acc;
      }

      totalNumberOfKeys++;

      const total = translations?.[key]?.status.total;
      const translated = translations?.[key]?.status.translated;
  
      if (total === translated) {
        numberOfCompletedKeys++;
      }

      return {
        ...acc,
        [key]: translations[key],
      };
    }, {});

    const filteredTranslationData = {
      data: {
        status: {
          total: totalNumberOfKeys,
          translated: numberOfCompletedKeys,
        },
        translations: filteredTranslations,
      }
    };

    if (totalNumberOfKeys === numberOfCompletedKeys) {
      return {
        status: Status.Success,
        namespace,
        type: SuccessTypes.AllTranslated,
        translations: filteredTranslationData,
        localizedFields: localizedFieldsData,
      };
    } else {
      // partially completed
      return {
        status: Status.Success,
        namespace,
        type: SuccessTypes.PartiallyTranslated,
        translations: filteredTranslationData,
        localizedFields: localizedFieldsData,
      };
    }
  } catch (error:any) {
    if (error.status === 404) {
      return Promise.reject({
        status: Status.Failure,
        type: FailureTypes.NoAssociatedGroup,
        error,
      });
    }

    return Promise.reject({
      status: Status.Failure,
      type: FailureTypes.OtherFailure,
      error,
    });
  }
};

export const authorTranslations = async ({
  translationServiceUrl,
  autoPublish,
  translations,
  managementAccessToken,
  space,
  testingMode = false,
}: {
  translationServiceUrl: string,
  autoPublish: boolean,
  translations: any,
  managementAccessToken: string,
  space: string,
  testingMode: boolean,
}) => {

  let requests: any
  // break request into batches if there are more than 5
  if (Object.keys(translations).length > 5) {
    const batchRequests = Object.keys(translations).reduce((acc, cur, index) => {
      const batch = Math.floor(index/BATCH_SIZE)
      if (!acc[batch]) acc[batch] = {};

      acc[batch][cur] = translations[cur];

      return acc
    }, [] as any)

    // stringify batches
    requests = batchRequests.map((batch: any) => {
     return JSON.stringify({
            data: {
              "translations": batch,
            } 
          })
    })
  } else {
  // if request has less than 5 keys, only stringify
    requests = [JSON.stringify({
      data: {
        translations,
      } 
    })];
  }

  let res: any;
  let resJson: any;
  if (!testingMode) {
   // throttle batched requests to 2 per second
   const throttle = throttledQueue(THROTTLE_AMOUNT, THROTTLE_TIME, true);
   try {
      res = await Promise.all(
        requests.map(
          (body:any) => throttle(async () => {
              const data = await fetch(`${translationServiceUrl}/page/translations${ autoPublish ? '?forcePublish=true' : ''}`, {
                method: 'PUT',
                headers: {
                  'X-Contentful-Access-Token': managementAccessToken,
                  'X-Contentful-Space': space,
                  'Content-Type': 'application/json',
                },
                body,
              }).catch((error:any)=> {
                // failed to fetch : timed out
                const errorObj = { status: 504, message: error }
                throw errorObj
              })
              const dataJson = await data.json()
              return dataJson
          })
      ))

      // reduce array of responses into one
      resJson = res.reduce((acc:any, cur:any) => {
        if (cur.status === 200) {
          // if any part of the request is successful, send through as at least partial success 
          acc.status = 200
          // push successful data to response
          cur.data.map((entry:any) => acc.data.push(entry))
        }
        // signify that part of the request failed
        if (cur.status >= 400) {
          const errorObj:any = {status: cur.status, name: cur.name, message: cur.message }
          acc.data.push(errorObj)
        }
          return acc
      })
    } catch (error : any) {
      if (error.status && error.message) {
         resJson = {
          status: error.status,
          message: error.message
        }
      }
      else {
        resJson = {
          status: 400,
          message: 'Unknown Error'
        }
      }
      return Promise.reject(resJson);
    }
  }

   else {
    resJson = { status:200 }
  }

  if (resJson && resJson.status >= 400) {
    return Promise.reject(resJson);
  }

  return resJson;
};
