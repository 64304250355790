import React, { useState } from 'react';
import fetch from 'isomorphic-fetch';
import {
  Button,
  Dropdown,
  DropdownList,
  DropdownListItem,
  List,
  ListItem,
  Note,
  Paragraph,
  Spinner,
} from '@contentful/forma-36-react-components';

import './RetrieveTranslationKeys.css';

const ENTRY_STATE = {
  delivery: 'Published',
  preview: 'Draft',
};

const RetrieveTranslationKeys = ({ sdk }: { sdk: any }) => {
  const [isEntryStateOpen, setEntryStateOpen] = useState(false);
  const [selectedEntryState, setSelectedEntryState] = useState('');
  const [isInProgress, setIsInProgress] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const [translationKeys, setTranslationKeys] = useState<string[]>([]);
  const [errorInfo, setErrorInfo] = useState('');

  const {
    parameters: {
      installation: {
        deliveryAccessToken = '',
        previewAccessToken = '',
        translationServiceUrl = '',
      } = {},
    } = {},
    ids: {
      space = '',
      entry = '',
      environment = '',
    } = {},
  } = sdk;

  const retrieveTranslation = () => {
    setIsInProgress(true);

    const apiType = selectedEntryState === 'Published' ? 'delivery' : 'preview';
  
    // handle submit
    fetch(`${translationServiceUrl}/page/${entry}/keys?apiType=${apiType}&environment=${environment}`, {
      headers: {
        'X-Contentful-Delivery-Access-Token': deliveryAccessToken,
        'X-Contentful-Preview-Access-Token': previewAccessToken,
        'X-Contentful-Space': space,
      }
    }).then(
      res => res.json()
    ).then(
      (res) => {
        if (res.status >= 400) {
          return Promise.reject(res);
        }

        // show success status
        const keys = Object.keys(res?.data ?? {});
        setTranslationKeys(keys);
        setErrorInfo('');
        setIsInProgress(false);
        setShowSuccessMessage(true);
      }
    ).catch(
      (error) => {
        // show failure status
        setErrorInfo(JSON.stringify(error));
        setIsInProgress(false);
        setShowFailureMessage(true);
      }
    );
  };

  return (
    <div className="wrapper">
      {
        !showSuccessMessage ? (
          <>
            {
              showFailureMessage && (
                <div className="failure-message-wrapper">
                  <Note title="Oops, something went wrong! Please try again." noteType="warning" className="failure-message-note">
                    { `${errorInfo}` }
                  </Note>
                </div>
              )
            }
            <div className="dropdown-wrapper">
            <Dropdown
              className="entry-state-dropdown"
              isOpen={isEntryStateOpen}
              onClose={() => setEntryStateOpen(false)}
              toggleElement={
                <Button
                  size="small"
                  buttonType="muted"
                  indicateDropdown
                  onClick={() => setEntryStateOpen(!isEntryStateOpen)}
                >
                  { selectedEntryState || 'Choose the entry state' }
                </Button>
              }
            >
              <DropdownList>
                <DropdownListItem onClick={() => setSelectedEntryState(ENTRY_STATE.delivery)}>
                  Published
                </DropdownListItem>
                <DropdownListItem onClick={() => setSelectedEntryState(ENTRY_STATE.preview)}>
                  Draft
                </DropdownListItem>
              </DropdownList>
            </Dropdown>
            <Button
              buttonType="primary"
              disabled={!selectedEntryState}
              onClick={() => retrieveTranslation()}
            >
              { isInProgress ? (<>Retrieving<Spinner /></>) : "Retrieve" }
            </Button>
          </div>
          </>
        ) : (
          <div className="success-message-wrapper">
            <Note noteType="positive" className="success-message-note">
              Successfully retrieved translation keys!
            </Note>
            <Paragraph className="success-message-paragraph-wrapper">
              <List>
                {
                  translationKeys.map((key) => {
                    return (
                      <ListItem key={key}>
                        { key }
                      </ListItem>
                    )
                  })
                }
              </List>
            </Paragraph>
          </div>
        )
      }
    </div>
  );
}

export default RetrieveTranslationKeys;
