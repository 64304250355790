import React, { useState } from 'react';
import { Tabs, Tab } from '@contentful/forma-36-react-components';
import { EditorExtensionSDK } from '@contentful/app-sdk';

import StartTranslation from './StartTranslation/StartTranslation';
import CheckTranslation from './CheckTranslation/CheckTranslation';
import RetrieveTranslationKeys from './RetrieveTranslationKeys/RetrieveTranslationKeys';

import './EntryEditor.css'

interface EditorProps {
  sdk: EditorExtensionSDK;
}

const Entry = (props: EditorProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  return (
    <div className="entry-editor-wrapper">
      <Tabs role="navigation" withDivider>
        <Tab id="first"
          className="tab"
          selected={selectedTabIndex === 0}
          onSelect={() => setSelectedTabIndex(0)}
        >
          Start translation
        </Tab>
        <Tab id="second"
          className="tab"
          selected={selectedTabIndex === 1}
          onSelect={() => setSelectedTabIndex(1)}
        >
          Check translation
        </Tab>
        <Tab id="third"
          className="tab"
          selected={selectedTabIndex === 2}
          onSelect={() => setSelectedTabIndex(2)}
        >
          Retrieve translation keys
        </Tab>
      </Tabs>
      {
        selectedTabIndex === 0 && (<StartTranslation sdk={props.sdk} />)
      }   
      {
        selectedTabIndex === 1 && (<CheckTranslation sdk={props.sdk} />)
      }
      {
        selectedTabIndex === 2 && (<RetrieveTranslationKeys sdk={props.sdk} />)
      }
    </div>
  );
};

export default Entry;
